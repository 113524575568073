<!--
 Copyright (C) 2024 bAvenir

 This program and the accompanying materials are made
 available under the terms of the Eclipse Public License 2.0
 which is available at https://www.eclipse.org/legal/epl-2.0/

 SPDX-License-Identifier: EPL-2.0
-->

<template>
  <Alert variant="destructive">
    <ExclamationTriangleIcon class="w-4 h-4" />
    <AlertTitle>{{ props.title }}</AlertTitle>
    <AlertDescription v-if="props.message">
      {{ props.message }}
    </AlertDescription>
  </Alert>
</template>

<script setup lang="ts">
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'
import { ExclamationTriangleIcon } from '@radix-icons/vue'

const props = defineProps<{
  title: string
  message?: string
}>()
</script>
