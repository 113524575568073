/**
 * Copyright (C) 2024 bAvenir
 *
 * This program and the accompanying materials are made
 * available under the terms of the Eclipse Public License 2.0
 * which is available at https://www.eclipse.org/legal/epl-2.0/
 *
 * SPDX-License-Identifier: EPL-2.0
 */

import axios from 'axios'
import { ref } from 'vue'

class Client {
  private apiClient
  private _token = ref<string | null | undefined>(undefined)

  constructor() {
    this.apiClient = axios.create({
      baseURL: 'https://weather-stations.spade.bavenir.eu',
      timeout: 10000,
      headers: { 'Content-Type': 'application/json' },
    })
    this._token.value = sessionStorage.getItem('token')
  }

  private getAuthHeaders() {
    if (!this._token.value) {
      throw new Error('No token available. Please log in first.')
    }
    return { Authorization: `Bearer ${this._token.value}` }
  }

  private setToken(token: string | null | undefined) {
    this._token.value = token
    if (token) {
      sessionStorage.setItem('token', token)
    } else {
      sessionStorage.removeItem('token')
    }
  }

  async healthcheck() {
    try {
      const response = await this.apiClient.get('/healthcheck')
      return response.data.message
    } catch (error) {
      console.error('Error fetching healthcheck:', error)
      throw error
    }
  }

  async getSHMUStations() {
    try {
      const response = await this.apiClient.get('/shmuStations', {
        headers: this.getAuthHeaders(),
      })
      return response.data.message
    } catch (error) {
      console.error('Error fetching SHMU stations:', error)
      throw error
    }
  }

  async getNetatmoStations() {
    try {
      const response = await this.apiClient.get('/netatmoStations', {
        headers: this.getAuthHeaders(),
      })
      return response.data.message
    } catch (error) {
      console.error('Error fetching Netatmo stations:', error)
      throw error
    }
  }

  async login(username: string, password: string) {
    try {
      const response = await this.apiClient.post('/login', { username, password })
      this.setToken(response.data.message)
    } catch (error) {
      console.error('Error logging in:', error)
      throw error
    }
  }

  async consumption<T>(oid: string, pid: string) {
    try {
      const response = await this.apiClient.get(`/consumption/${oid}/${pid}`, {
        headers: this.getAuthHeaders(),
      })
      return response.data.message as T
    } catch (error) {
      console.error('Error fetching consumption:', error)
      throw error
    }
  }

  get isLoggedIn() {
    return this._token.value !== undefined && this._token.value !== null && this._token.value !== ''
  }
}

export const ApiClient = new Client()
