/**
 * Copyright (C) 2024 bAvenir
 *
 * This program and the accompanying materials are made
 * available under the terms of the Eclipse Public License 2.0
 * which is available at https://www.eclipse.org/legal/epl-2.0/
 *
 * SPDX-License-Identifier: EPL-2.0
 */

import { ApiClient } from '@/client'
import { Netatmo, NetatmoHistoryDataResponse, NetatmoResponse, NetatmoValue } from '@/models/netatmo'
import { SHMU, SHMUResponse } from '@/models/shmu'
import { Healthcheck, ItemTD } from '@bavenir/spade-node-js-client'
import { defineStore } from 'pinia'

interface State {
  shmuStations: {
    loading: boolean
    failed: boolean
    value: ItemTD[]
  }
  netatmoStations: {
    loading: boolean
    failed: boolean
    value: ItemTD[]
  }
  healthcheck?: Healthcheck
}

export const useDataStore = defineStore('dataStore', {
  state: (): State => {
    return {
      shmuStations: {
        loading: false,
        failed: false,
        value: [],
      },
      netatmoStations: {
        loading: false,
        failed: false,
        value: [],
      },
    }
  },
  actions: {
    async init() {
      await this.runHealthcheck()
    },
    async runHealthcheck() {
      this.healthcheck = await ApiClient.healthcheck()
    },
    async login(username: string, password: string) {
      await ApiClient.login(username, password)
    },
    async discoverSHMU() {
      if (!this.healthcheck) {
        throw new Error('Healthcheck have not been run yet')
      }
      try {
        this.shmuStations.loading = true
        this.shmuStations.value = await ApiClient.getSHMUStations()
      } catch (e) {
        console.error(e)
        this.shmuStations.failed = true
      } finally {
        this.shmuStations.loading = false
      }
    },

    async discoverNetatmo() {
      try {
        if (!this.healthcheck) {
          throw new Error('Healthcheck have not been run yet')
        }
        this.netatmoStations.loading = true
        this.netatmoStations.value = await ApiClient.getNetatmoStations()
      } catch (e) {
        console.error(e)
        this.netatmoStations.failed = true
      } finally {
        this.netatmoStations.loading = false
      }
    },

    async readSHMUProps(item: ItemTD): Promise<SHMU> {
      if (!this.healthcheck) {
        throw new Error('Healthcheck have not been run yet')
      }
      const oid = item.oid
      const result: SHMU = {}
      await Promise.all(
        Object.keys(item.properties ?? {}).map(async (key) => {
          try {
            result[key] = await ApiClient.consumption<SHMUResponse[]>(oid, key)
          } catch (e) {
            console.error(e)
            result[key] = undefined
          }
        })
      )
      return result
    },

    async readNetatmoProps(item: ItemTD): Promise<Netatmo> {
      if (!this.healthcheck) {
        throw new Error('Healthcheck have not been run yet')
      }
      const oid = item.oid
      const result: Netatmo = {}
      await Promise.all(
        Object.keys(item.properties ?? {})
          .filter((key) => key != 'historyData')
          .map(async (key) => {
            try {
              const res = await ApiClient.consumption<NetatmoResponse>(oid, key)
              result[key] = { ...res, value: res.value }
            } catch (e) {
              console.warn(e)
              try {
                const res = await ApiClient.consumption<NetatmoHistoryDataResponse[]>(oid, 'historyData')
                const last = res[res.length - 1]
                if (last && last.value) {
                  result[key] = { ...last, value: last.value[key as keyof NetatmoValue] }
                } else {
                  result[key] = undefined
                }
              } catch (e) {
                console.error(e)
                result[key] = undefined
              }
            }
          })
      )
      return result
    },
  },
  getters: {
    isLoggedIn(): boolean {
      return ApiClient.isLoggedIn
    },
    isReady(): boolean {
      return this.healthcheck !== undefined
    }
  },
})
