import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-screen h-dvh"
}
const _hoisted_2 = {
  key: 0,
  class: "w-full h-full flex items-center justify-center"
}
const _hoisted_3 = {
  key: 1,
  class: "w-full h-full flex items-center justify-center"
}
const _hoisted_4 = { class: "min-w-[400px] max-w-[600px]" }
const _hoisted_5 = {
  key: 1,
  class: "w-screen h-screen flex items-center justify-center"
}

import { onMounted, ref } from 'vue'
import { useDataStore } from '@/store/dataStore'
import ErrorAlert from '@/components/ErrorAlert.vue'
import LoginCard from '@/components/LoginCard.vue'
import { ReloadIcon } from '@radix-icons/vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const dataStore = useDataStore()

const error = ref<string | null | undefined>(undefined)

async function initApp() {
  try {
    await dataStore.init()
  } catch (e) {
    if (e instanceof Error) {
      error.value = e.message
    }
  }
}

onMounted(async () => {
  initApp()
})

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_unref(dataStore).isReady)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (!error.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (!_unref(dataStore).isLoggedIn)
                ? (_openBlock(), _createBlock(LoginCard, { key: 0 }))
                : (_openBlock(), _createBlock(_component_router_view, { key: 1 }))
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(ErrorAlert, {
                  title: "Error initilizing app",
                  message: error.value
                }, null, 8, ["message"])
              ])
            ]))
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_5, [
        _createVNode(_unref(ReloadIcon), { class: "w-4 h-4 mr-2 animate-spin" }),
        _cache[0] || (_cache[0] = _createElementVNode("div", null, "Loading", -1))
      ]))
}
}

})