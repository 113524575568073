<!--
 Copyright (C) 2024 bAvenir

 This program and the accompanying materials are made
 available under the terms of the Eclipse Public License 2.0
 which is available at https://www.eclipse.org/legal/epl-2.0/

 SPDX-License-Identifier: EPL-2.0
-->

<template>
  <form class="w-[360px]" @submit.prevent="login()">
    <Card>
      <div class="flex flex-col gap-4">
        <Input v-model="email" placeholder="Email" />
        <Input type="password" v-model="password" placeholder="Password" />
        <div v-if="error" class="mt-4">
          <ErrorAlert title="Failed to login" :message="error" />
        </div>
        <div class="mt-4">
          <Button @click="login()" :disabled="disabled || loading" type="submit" style="width: 100%">
            <ReloadIcon v-if="loading" class="w-4 h-4 mr-2 animate-spin" />
            Login
          </Button>
        </div>
      </div>
    </Card>
  </form>
</template>

<script setup lang="ts">
import { useDataStore } from '@/store/dataStore'
import Card from '@/components/Card.vue'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { computed, ref } from 'vue'
import ErrorAlert from '@/components/ErrorAlert.vue'
import { ReloadIcon } from '@radix-icons/vue'

const dataStore = useDataStore()

const email = ref('')
const password = ref('')
const loading = ref(false)

const error = ref<string | null>(null)

async function login() {
  try {
    error.value = null
    loading.value = true
    await dataStore.login(email.value, password.value)
  } catch (e) {
    if (e instanceof Error) {
      error.value = e.message
    }
  } finally {
    loading.value = false
  }
}

const disabled = computed(() => !email.value || !password.value)
</script>

<style lang="scss" scoped></style>
