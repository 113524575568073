import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'
import { ExclamationTriangleIcon } from '@radix-icons/vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'ErrorAlert',
  props: {
    title: {},
    message: {}
  },
  setup(__props: any) {

const props = __props

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Alert), { variant: "destructive" }, {
    default: _withCtx(() => [
      _createVNode(_unref(ExclamationTriangleIcon), { class: "w-4 h-4" }),
      _createVNode(_unref(AlertTitle), null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(props.title), 1)
        ]),
        _: 1
      }),
      (props.message)
        ? (_openBlock(), _createBlock(_unref(AlertDescription), { key: 0 }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(props.message), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})