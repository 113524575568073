import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-4" }
const _hoisted_2 = {
  key: 0,
  class: "mt-4"
}
const _hoisted_3 = { class: "mt-4" }

import { useDataStore } from '@/store/dataStore'
import Card from '@/components/Card.vue'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { computed, ref } from 'vue'
import ErrorAlert from '@/components/ErrorAlert.vue'
import { ReloadIcon } from '@radix-icons/vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginCard',
  setup(__props) {

const dataStore = useDataStore()

const email = ref('')
const password = ref('')
const loading = ref(false)

const error = ref<string | null>(null)

async function login() {
  try {
    error.value = null
    loading.value = true
    await dataStore.login(email.value, password.value)
  } catch (e) {
    if (e instanceof Error) {
      error.value = e.message
    }
  } finally {
    loading.value = false
  }
}

const disabled = computed(() => !email.value || !password.value)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("form", {
    class: "w-[360px]",
    onSubmit: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (login()), ["prevent"]))
  }, [
    _createVNode(Card, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_unref(Input), {
            modelValue: email.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((email).value = $event)),
            placeholder: "Email"
          }, null, 8, ["modelValue"]),
          _createVNode(_unref(Input), {
            type: "password",
            modelValue: password.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((password).value = $event)),
            placeholder: "Password"
          }, null, 8, ["modelValue"]),
          (error.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(ErrorAlert, {
                  title: "Failed to login",
                  message: error.value
                }, null, 8, ["message"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_unref(Button), {
              onClick: _cache[2] || (_cache[2] = ($event: any) => (login())),
              disabled: disabled.value || loading.value,
              type: "submit",
              style: {"width":"100%"}
            }, {
              default: _withCtx(() => [
                (loading.value)
                  ? (_openBlock(), _createBlock(_unref(ReloadIcon), {
                      key: 0,
                      class: "w-4 h-4 mr-2 animate-spin"
                    }))
                  : _createCommentVNode("", true),
                _cache[4] || (_cache[4] = _createTextVNode(" Login "))
              ]),
              _: 1
            }, 8, ["disabled"])
          ])
        ])
      ]),
      _: 1
    })
  ], 32))
}
}

})