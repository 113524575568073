<!--
 Copyright (C) 2024 bAvenir

 This program and the accompanying materials are made
 available under the terms of the Eclipse Public License 2.0
 which is available at https://www.eclipse.org/legal/epl-2.0/

 SPDX-License-Identifier: EPL-2.0
-->

<template>
  <div v-if="dataStore.isReady" class="w-screen h-dvh">
    <div v-if="!error" class="w-full h-full flex items-center justify-center">
      <LoginCard v-if="!dataStore.isLoggedIn" />
      <router-view v-else />
    </div>
    <div class="w-full h-full flex items-center justify-center" v-else>
      <div class="min-w-[400px] max-w-[600px]">
        <ErrorAlert title="Error initilizing app" :message="error"> </ErrorAlert>
      </div>
    </div>
  </div>
  <div v-else class="w-screen h-screen flex items-center justify-center">
    <ReloadIcon class="w-4 h-4 mr-2 animate-spin" />
    <div>Loading</div>
  </div>
</template>

<style lang="scss"></style>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useDataStore } from '@/store/dataStore'
import ErrorAlert from '@/components/ErrorAlert.vue'
import LoginCard from '@/components/LoginCard.vue'
import { ReloadIcon } from '@radix-icons/vue'

const dataStore = useDataStore()

const error = ref<string | null | undefined>(undefined)

async function initApp() {
  try {
    await dataStore.init()
  } catch (e) {
    if (e instanceof Error) {
      error.value = e.message
    }
  }
}

onMounted(async () => {
  initApp()
})
</script>
