import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '../assets/spade-logo.svg'


import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card'

export default /*@__PURE__*/_defineComponent({
  __name: 'Card',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Card), null, {
    default: _withCtx(() => [
      _createVNode(_unref(CardHeader), null, {
        default: _withCtx(() => [
          _createVNode(_unref(CardTitle), null, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode("SPADE Weather Map Service")
            ])),
            _: 1
          }),
          _createVNode(_unref(CardDescription), null, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("This is an example of spade service")
            ])),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_unref(CardContent), null, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }),
      _createVNode(_unref(CardFooter), null, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createElementVNode("div", { class: "w-full flex items-center justify-center" }, [
            _createElementVNode("img", {
              src: _imports_0,
              alt: "Spade Logo",
              style: {"width":"100px"}
            })
          ], -1)
        ])),
        _: 1
      })
    ]),
    _: 3
  }))
}
}

})